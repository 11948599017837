import { render, staticRenderFns } from "./Contactus.vue?vue&type=template&id=6b3afa0a&scoped=true&"
import script from "./Contactus.vue?vue&type=script&lang=js&"
export * from "./Contactus.vue?vue&type=script&lang=js&"
import style0 from "./Contactus.vue?vue&type=style&index=0&id=6b3afa0a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3afa0a",
  null
  
)

export default component.exports